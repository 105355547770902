
  .register {
    font-family: 'Arial', sans-serif;
    display: flex;
    height: 100vh;
  }
  
  .register-left,
  .register-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .register-left .register-backgroundimg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .register-right {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
 
  }
  
  .register-right h1 {
    align-items: center;
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .registration-form {
    border-radius: 15px;
    padding: 3%;
    width: 100%;
    max-width: 400px;
    border: 0.5rem solid gray;
    background-color: white;
  }
  
  .register-right label {
    font-weight: bold;
  }
  
  .register-right input {
    height: 40px;
    width: 100%;
    max-width: 350px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 14px;
  }
  
  .register-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-left: 40%;
  }
  
  .register-btn:hover {
    background-color: #0056b3;
  }
  
  .password-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .password-input-wrapper input {
    width: 100%;
  }
  
  .password-input-wrapper svg {
    position:absolute;
    margin-left: 85%;
    cursor: pointer;
  }
  
  .error-message,
  .success-message {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .register-link {
    margin-top: 15px;
  }
  
  .register-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }
  
  .register-text {
    margin-top: 10px;
  }
  .register-btn1{
    margin-left: 5%;
    background-color: #fff;
  border: 1px solid #25D366;
  padding: 5px;
  position: relative;
  width: 6.2em;
  height: 2em;
  transition: 0.5s;
  font-size: 17px;
  border-radius: 0.4em;
  }
  .register-btn1 a{
    position: absolute;
  top: 0.4em;
  left: 1.15em;
  margin: 0;
  padding: 0;
  transition: .5s;
  color: #25D366;
  }
  
  .register-btn1:hover {
    background-color: #25D366;
    color: white;
  }
  
  
/* Responsive Styles */

/* Mobile (max-width: 768px) */
@media screen and (max-width: 768px) {
  .register {
    flex-direction: column;
    height: auto;
  }

  .register-left {
    display: none;
  }

  .register-right {
    width: 100%;
    padding: 10px;
  }

  .registration-form {
    margin-top: 15%;
    width: 100%;
    padding: 2%;
    border: 0.3rem solid gray;
  }

  .register-right input {
    max-width: 100%;
  }

  .register-btn {
    width: 50%;
    text-align: center;
    margin-left: 25%;
  }

  .register-btn1 {
    width: 100%;
    height: auto;
    margin-left: 0;
    padding: 10px;
  }

  .register-btn1 a {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Mobile (max-width: 480px) */
@media screen and (max-width: 480px) {
  .register-right h1 {
    font-size: 24px;
  }

  .register-right input {
    height: 35px;
    font-size: 14px;
  }

  .register-btn {
    padding: 8px 16px;
    font-size: 16px;
  }

  .register-btn1 {
    padding: 8px;
    font-size: 15px;
  }
}
.registration-form {
  width: 90%;
  padding: 20px;
  border: 0.3rem solid gray;
}
  