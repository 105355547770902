.Dashboard-Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 100px;
  }
  
  
  .Dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
    gap: 20px;
    justify-content: center;
  }
  
  .Grid {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .Grid:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .Image {
    max-width: 100%;
    height: 5vh;
    margin-bottom: 7px;
  }
  
  .Grid-heading {
    font-size: 0.8rem;
    color: #333;
    margin-bottom: 3px;
  }
  
  .Grid-paragraph {
    font-size: 0.6rem;
    color: #666;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  

  @media screen and (max-width: 768px) {
    .Dashboard-grid {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .Dashboard-grid {
      grid-template-columns: 1fr; 
      gap: 10px; 
      width: 100%;
    }
    .Grid {
      padding: 15px; 
    }
  }