.Profile-container {
  display: flex;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-bottom: 100px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 500px;
}

/* .Right-side-container, .left-side-container {
  flex: 1;
} */

.profile-form-container, .address-form-container, .kyc-form-container {
  margin-bottom: 5px;
}

.profile-form-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.photo-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Photo-Preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  margin-bottom: 5px;
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #888;
  font-size: 14px;
}

.photo-edit-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 3px;
  font-size: 15px;
  cursor: pointer;
  border: 2px solid #ccc;
  transition: all 0.3s ease;
}

.photo-edit-icon:hover {
  background-color: #999999;
  border-color: #888;
}

.photo-input {
  display: none;
}

.photo-upload:hover .photo-edit-icon {
  opacity: 1;
  visibility: visible;
}


.Profile-form-label {
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 14px;
}

.Profile-form-input {
  width: 100%;
  padding: 3px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 12px;
}

.Profile-form-input:focus {
  border-color: #1890ff;
  outline: none;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.Active-Group {
  margin-bottom: 20px;
}

.active{
  margin-left: 10px;
  width: 15px;
  height: 15px;
}

.address-kyc-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.address-kyc-table th, .address-kyc-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.address-kyc-table th {
  background-color: #f1f1f1;
}

.kyc-form-group {
  margin-bottom: 5px;
}

.kyc-data table {
  width: 100%;
  margin-top: 20px;
}

.kyc-data th, .kyc-data td {
  padding: 8px;
  text-align: left;
}

.kyc-data th {
  background-color: #f9f9f9;
}

h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.Profile-form-group select {
  width: 100%;
  padding: 2px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.Profile-form-group select:focus {
  outline: none;
  border-color: #007BFF;
}


.Add-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.SUBMIT-button {
  display: inline-block;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  padding: 0.5em 1em 0.5em 1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
}

.SUBMIT-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}