.Whole-Content {
  height: 100vh; 
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  background-color: white;
  margin: 0 auto;
  background-image: url('https://t4.ftcdn.net/jpg/02/31/11/99/240_F_231119940_P6c6oC6yhnT7FGOfuzudyk9wnp1N1UdH.jpg');
  background-size: cover;
}

.Heading {
  margin-bottom: 20px;
}

.Heading h4 {
  font-size: 1.6rem;
  margin: 0;
}

.Heading p {
  color: white;
  font-size: 1rem;
  margin: 5px 0;
}

.role-selection {
  display: grid;
  justify-content: center;
  gap: 15px;
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
}

.button-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 15px 12px 14px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border: 2px solid black;
}

.button-container:hover {
  transform: scale(1.05);
}

.button-container img {
  width: 80px;
  margin-right: 15px;
}

.button-right-block {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
}

.button-label1 {
  font-weight: bold;
  font-size: 1.2rem;
}

.button-label2 {
  font-size: 0.9rem;
  color: #555;
}

.MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  color: #333;
  margin-left: 30%;
}

.jss10 {
  margin-top: 10px;
  font: 1em sans-serif;
}

.jss11 {
  color: #007bff;
  text-decoration: none;
}

.jss11:hover {
  text-decoration: underline;
}

.LoginRoleSelect_footerSection__1tyI5 {
  margin-top: 2%;
  font-size: 0.8rem;
}

.LoginRoleSelect_footerSection__1tyI5 a {
  color: #007bff;
  text-decoration: none;
}

.LoginRoleSelect_footerSection__1tyI5 a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) { 
  .Heading h4 {
      font-size: 1.4rem;
  }

  .Heading p {
      font-size: 0.9rem;
  }

  .button-container {
      max-width: 100%;
      padding: 15px;
  }

  .button-label1 {
      font-size: 1.1rem;
  }

  .button-label2 {
      font-size: 0.8rem;
  }

  .jss10 {
      font-size: 0.9em;
  }

  .LoginRoleSelect_footerSection__1tyI5 {
      font-size: 0.7rem;
  }
}

@media (max-width: 480px) { 
  .Heading h4 {
    font-size: 1.4rem;
}

.Heading p {
    font-size: 0.9rem;
}

.button-container {
    max-width: 100%; 
    padding: 5px; 
}

.button-label1 {
    font-size: 1.1rem; 
}

.button-label2 {
    font-size: 0.8rem; 
}

.jss10 {
    font-size: 0.9em; 
}

.LoginRoleSelect_footerSection__1tyI5 {
    font-size: 0.7rem;
}
}
