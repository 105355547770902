.panels-container {
    margin-top: 5px;
    gap: 5px;
    margin-bottom: 25px;
  }
  
  .panel {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    margin-bottom: 5px;
  }

  .Panel {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
  
  
  
  .panel-header {
    padding: 2px 30px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #F9C22E;
    color: black;
    border-radius: 5px ;
  }
  
  .panel-header:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  .toggle-button {
    background-color: transparent;
    border: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .panel-header:hover .toggle-button {
    color: #fff;
  }
  
  .panel-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
  }
  

  /* Table styling */

  .Table {
    padding: 5px;
    overflow-x: auto; 
  -webkit-overflow-scrolling: touch;
  font-size: 12px;
  }

  .Table table {
    width: 100%;
  text-align: center;
  border-collapse: collapse; 
  border-spacing: 0;
  /* table-layout: fixed; */
  }
  
  .Table th, .Table td {
    vertical-align: middle;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0);
    word-wrap:normal; 
  }
  
  .Table thead th {
    background-color: #fad368;
  color: rgb(0, 0, 0);
  }
  
  .panel-container {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  
  .Panel-form-label {
    font-weight: 500;
    color: #333;
    display: block;
    font-size: 14px;
  }
  
  .Panel-form-input {
    width: 100%;
    padding: 3px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
  }
  
  .Panel-form-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  select.Panel-form-input {
    appearance: none;
    background-color: #fff;
    cursor: pointer;
  }
  
  .Panel-Add-Button {
    display: flex;
    justify-content: flex-end;
  }
  
  
.submit-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5em 1em 0.5em 1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
  }
  
  
  .submit-button:hover {
    box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
  }
  
  .submit-button:active {
    box-shadow: 0 0.3em 1em -0.5em #14a73e98;
  }


/* Center Popup */
.centre-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Center Popup Content */
.centre-popup-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.centre-popup-form{
  padding: 10px;
  border: 1px solid #9CEAEF;
}

/* Close Button */
.Centre-close {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.Centre-close:hover {
  color: #f00;
}

/* Header */
.centre-popup-content h5 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  background-color: #9CEAEF;
  line-height: 1.5;
}

/* Centre Table */
.centre-table {
  width: 100%;
  border-collapse: collapse;
}

.centre-table th,
.centre-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.centre-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.centre-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Centre Popup Table No Data */
.centre-table tr td[colspan="2"] {
  text-align: center;
  font-style: italic;
  color: #888;
}


  /* Media query for mobile screens */
@media (max-width: 768px) {
  .Table table {
    width: 100%;
    table-layout: auto; 
  }

  .Table th, .Table td {
    padding: 8px 5px; 
    font-size: 14px; 
  }
}