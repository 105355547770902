.Total-Content {
  display: flex;
  height: 100vh;
  background: linear-gradient(75deg, #ff5c0b, #ff5c0b 15%, #f2a450 0, #f2a450 45%, #f6c562 0, #f6c562 75%, #f9da72 0, #f9da72);
  width: 45%;
  background-position: center;
}

.background {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.Logo {
  position: absolute;
  left: 15%;
  top: 25%;
  transform: translateY(-50%);
}

.Logo img {
  height: 200px;
  width: 200px;
  border-radius: 50px;
}

.login-Container {
  width: 350px; 
  padding: 30px;
  background-color: white; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  position: absolute; 
  left: 50%; 
  top: 50%; 
  transform: translate(-50%, -50%);
  
}

.back-arrow {
  width: 100%;
  display: flex;
  align-items: center; 
  margin-bottom: 10px; 
}

.bi-arrow-left {
  font-size: 24px; 
  margin-right: 10px;
  color:black;
}
.bi-arrow-left:hover{
  color: #007bff;
}

.back-button h4 {
  font-weight: bold;
}

.login-title {
  font-size: 20px; 
  text-align: center; 
  flex: 1; 
  font-weight: 600;
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-bottom: 5px;
}

.input-Container1,
.input-Container2 {
  width: 100%;
  margin-bottom: 10px;
  position: relative; 
}

.input-label {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2px;
  display: block;
}

.input-field {
  width: 100%;
  padding: 4px 10px;
  border: 1px solid #857e7e;
  border-radius: 7px;
  font-size: 13px;
}

.input-field:focus{
  border-color: #1890ff;
  outline: none;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.Login-form-Password-icon {
  position: absolute;
  right: 15px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
}

.forgot-password-container{
  display: flex;
  justify-content: flex-end;
}

.forgot-password{
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.Login-button-container{
  display: flex;
  justify-content: center;
}

.login-button {
  margin-top: 5px;
  width: 50%;
  padding: 5px 10px;
  background-color: #052e3a;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; 
  transition: background-color 0.3s ease;
  position: relative; 
}

.login-button:hover {
  background-color: #004a61;
}

.login-button svg {
  fill: white;
  width: 20px;
  height: 15px; 
  transition: transform 0.3s ease;
}

.login-button:hover svg {
  transform: translateX(5px); 
}

.google-button {
  width: 100%;
  padding: 5px 10px;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.google-button:hover {
  background-color: #f5f5f5;
}

.google-button svg {
  height: 20px;
}


/* Desktop (default styles) */
@media (min-width: 769px) {
  .Total-Content {
    flex-direction: row; 
  }
  .login-Container{
    margin-left: 20%;
  }
}

/* Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .Total-Content {
    flex-direction: column; 
    width: 100%;
  }

  /* .Logo {
    display: none;
  } */

  .Logo {
    left: 40%;
    top: 15%
  }

  .login-Container {
    border-radius: 10px;
    max-width: 90%;
    padding: 20px;
  }

  .login-title {
    font-size: 1.5rem; 
  }

  .input-label,
  .input-field {
    font-size: 0.875rem; 
  }

  .login-button {
    width: 50%;
    font-size: 0.875rem; 
  }
}

/* Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .Total-Content {
    padding-left: 2%;
    flex-direction: column;
    align-items: center;
  }

  .Logo {
    display: none;
  }

  .login-title {
    font-size: 1.2rem;
  }

  .input-Container1,
  .input-Container2 {
    margin-bottom: 10px; 
  }

  .input-field {
    font-size: 0.75rem;
    padding: 8px; 
  }

  .password-icon {
    top: 70%;
  }

  .login-button {
    text-align: center;
    width: 50%;
    font-size: 0.75rem;
  }

  .google-button {
    font-size: 0.75rem;
    padding: 8px; 
  }

  .bi-arrow-left {
    font-size: 20px; /* Smaller arrow icon */
  }

  .forgot-password h4 {
    font-size: 0.75rem; 
  }
}