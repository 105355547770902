
.chapter-details {
  overflow-y: auto;
  margin-bottom: 100px;
  width: 100%;
}

.list-Container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SubjectName-Container {
  margin: 10px 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.Chapter-Name {
  background-color: lightgreen;
  padding: 3px 15px;
  margin: 5px 0;
  color: black;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #ccc;
}





.expanded-content {
  overflow-y: auto;  
}


.chapter-details h6 {
  text-align: center;
  font-size: 1rem;
  color: #333;
}

.Three-button {
  text-align: center;
  display: flex;                
  justify-content: space-evenly; 
  align-items: center;         
  margin: 20px 0;           
  gap: 15px;  
  flex-wrap: wrap;
}

.content-button{
  align-items: center;
  text-align: center;
  color: white;                
  border: none;              
  border-radius: 8px;        
  padding: 2px 10px;         
  font-size: 12px;            
  cursor: pointer;             
  transition: background-color 0.3s ease; 
}

.ppt-button {
  background-color: rgb(245, 56, 56);
}

.video-button {
  background-color: rgb(231, 79, 24);
}

.assignment-button {
  background-color: rgb(43, 88, 235);
}

.activity-video-button {
  background-color: rgb(70, 130, 180);
}

.activity-item-button {
  background-color: rgb(34, 139, 34);
}

.simulation-video-button {
  background-color: rgb(128, 0, 128);
}

.worksheet-button {
  background-color: darkred;
}

.content-button:hover {
  opacity: 0.8;
}

.Three-button i {
  font-size: 1rem;
  margin-right: 10px;
}

.topic-name {
  display: flex;
  align-items: center;
}



.expanded-topic ul ul li {
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.expanded-topic .Three-button {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content h3 {
  margin: 0 0 20px;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Close-btn {
  position: absolute;
  max-width: 70px;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #dc3545;
}

.modal-content .close-button:hover {
  color: #b02a35;
}

.Chapter-edit-modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 350px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Overlay Background */
.Chapter-edit-modal::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Modal Heading */
.Chapter-Add-heading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

/* Label Styling */
.Chapter-edit-modal-label {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  display: block;
}

.Topic-edit-modal-input {
  width: 100%; 
  padding: 10px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px; 
}

.Topic-edit-modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  background: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
}

.Topic-edit-modal-heading {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Topic-Name-Change{
  margin-bottom: 20px;
}

.Topic-edit-modal-form-group {
  margin-bottom: 10px;
  display: flex;
}

.Topic-edit-modal-label {
  display: block;
  font-weight: 500;
  width: 100%;
  max-width: 100px;
  font-size: 15px;
}

.Topic-edit-modal-input {
  width: 100%;
  max-width: 300px;
  padding: 3px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
}

.Topic-edit-modal-input:focus{
  border-color: #1890ff;
  outline: none;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}


.content-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  max-height: 250px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.content-table th,
.content-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: center;
}

.content-table th {
  background: #f4f4f4;
  font-weight: 500;
  font-size: 13px;
}

.content-table td{
  font-size: 11px;
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 5px;
}

.remove-button img {
  width: 15px;
  height: 15px;
  transition: transform 0.2s;
}

.remove-button:hover img {
  transform: scale(1.2);
}

.Topic-Submit-button{
  display: flex;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  padding: 0.5em 1em 0.5em 1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
}
.Topic-edit-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Content-Add-button {
  margin-top: 15px;
  position: relative;
  width: 150px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #36f;
  border-radius: 5px;
  background-color: #36f;
  box-shadow: 4px 8px 10px -3px rgba(0, 0, 0, 0.356);
}

.Content-Add-button, .button__icon, .button__text {
  transition: all 0.3s;
}

.Content-Add-button .button__text {
  transform: translateX(20px);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.Content-Add-button .button__icon {
  position: absolute;
  transform: translateX(105px);
  height: 100%;
  width: 35px;
  height: 35px;
  background-color: #36f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Content-Add-button .svg {
  width: 35px;
  stroke: #fff;
}

.Content-Add-button:hover {
  background: rgb(13, 104, 249);
}

.Content-Add-button:hover .button__text {
  color: transparent;
}

.Content-Add-button:hover .button__icon {
  width: 135px;
  transform: translateX(0);
}

.Content-Add-button:active .button__icon {
  background-color: rgb(13, 104, 249);
}

.Content-Add-button:active {
  border: 1px solid rgb(13, 104, 249);
}


.close {
  position: absolute;
  top: 0;
  right: 15px;
  font-weight: bold;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}


.Submit-button {
  display: flex;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  padding: 0.5em 1em 0.5em 1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
}


.Submit-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.Submit-button:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}

.close:hover{
  color: #dd1a1a;
}

.Chapter-Add-button-container{
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.Chapter-Add-button {
  position: relative;
  width: 150px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #36f;
  border-radius: 5px;
  background-color: #36f;
  box-shadow: 4px 8px 10px -3px rgba(0, 0, 0, 0.356);
}

.Chapter-Add-button, .button__icon, .button__text {
  transition: all 0.3s;
}

.Chapter-Add-button .button__text {
  transform: translateX(20px);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.Chapter-Add-button .button__icon {
  position: absolute;
  transform: translateX(105px);
  height: 100%;
  width: 35px;
  height: 35px;
  background-color: #36f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Chapter-Add-button .svg {
  width: 35px;
  stroke: #fff;
}

.Chapter-Add-button:hover {
  background: rgb(13, 104, 249);
}

.Chapter-Add-button:hover .button__text {
  color: transparent;
}

.Chapter-Add-button:hover .button__icon {
  width: 135px;
  transform: translateX(0);
}

.Chapter-Add-button:active .button__icon {
  background-color: rgb(13, 104, 249);
}

.Chapter-Add-button:active {
  border: 1px solid rgb(13, 104, 249);
}

.Chapter-Add-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Chapter-Add-heading {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
}

.Chapter-Add-modal-input {
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}


.chapter-details ul {
  list-style: none;
  padding: 0;
}

.Chapter-list {
  width: 100%;
  background-color: #94cfbc;
  color:black;
  border: 1px solid #ccc;
  padding: 7px 25px;
  font-size: 0.9rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
}

.Chapter-list:hover {
  color: whitesmoke;
  
  .Edit-icon :hover{
    color: wheat;
  }
}

.Chapter-edit-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

.Chapter-edit-svgIcon {
  width: 8px;
  transition-duration: 0.3s;
  margin-bottom: 7px;
}

.Chapter-edit-svgIcon path {
  fill: white;
}

.Chapter-edit-button:hover {
  width: 150px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.Chapter-edit-button:hover .edit-svgIcon {
  width: 8px;
  transition-duration: 0.3s;
  transform: translateY(60%);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.Chapter-edit-button::before {
  display: none;
  content: "Update Chapter";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.Chapter-edit-button:hover::before {
  display: block;
  padding-right: 10px;
  font-size: 13px;
  opacity: 1;
  transform: translateY(0px);
  transition-duration: 0.3s;
}


.Btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 25px;
  height: 25px;
  border-radius: calc(25px/2);
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background: linear-gradient(144deg,#af40ff,#5b42f3 50%,#00ddeb);
  margin-left: 20px;
}

.sign {
  width: 100%;
  font-size: 1.5em;
  color: white;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1em;
  font-weight: 500;
  transition-duration: .3s;
}

.Btn:hover {
  width: 130px;
  transition-duration: .3s;
}

.Btn:hover .sign {
  width: 30%;
  transition-duration: .3s;
  padding-left: 5px;
}

.Btn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: .3s;
  padding-right: 15px;
}

.Btn:active {
  transform: translate(2px ,2px);
}

.chapter-details ul ul {
  padding-left: 20px;
  margin-top: 5px;
}

.chapter-details ul ul li {
  padding: 3px 15px;
  background-color: #94cfbc;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chapter-details ul ul li:hover {
  background-color: white;
}

.chapter-details ul ul li div {
  font-weight: 500;
}

.edit-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

.edit-svgIcon {
  width: 11px;
  transition-duration: 0.3s;
}

.edit-svgIcon path {
  fill: white;
}

.edit-button:hover {
  width: 80px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.edit-button:hover .edit-svgIcon {
  width: 10px;
  transition-duration: 0.3s;
  transform: translateY(60%);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.edit-button::before {
  display: none;
  content: "Content";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.edit-button:hover::before {
  display: block;
  padding-right: 10px;
  font-size: 12px;
  opacity: 1;
  transform: translateY(0px);
  transition-duration: 0.3s;
}


.media-player {
  position: relative;
  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 5px;
}

.media-player h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.media-player iframe {
  width: 100%;
  border-radius: 4px;
}

.ppt-viewer {
  position: relative;
  max-height: 800px;
  overflow-y: auto;
  margin-top: 10px;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 5px;
}


.Content-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.Content-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.Content-modal-heading {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.Content-modal-input-container {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.Content-modal-input-container-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #333;
}



.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}



input::placeholder {
  color: #aaa;
  font-style: italic;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  position: relative;
}

.close-video {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}



@media (max-width: 768px) {
  .chapter-details {
    padding: 10px;
  }

  .chapter-details h6 {
    font-size: 0.5rem;
  }

  .Three-button {
    flex-direction: column;
    gap: 10px;
  }

  .First-button, .Second-button, .Third-button, .Fourth-button, .Fifth-button, .Sixth-button {
    max-width: 100%;
    font-size: 14px;
    padding: 5px 10px;
  }

  .Three-button i {
    font-size: 1rem;
    margin-right: 8px;
  }

  .topic-name {
    flex-direction: column;
    text-align: center;
  }

  .topic-icons {
    flex-direction: column;
  }

  .icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
    margin-left: 0;
    padding: 8px 10px;
  }

  .expanded-topic ul ul li {
    padding: 8px 12px;
    margin: 8px 0;
  }

  .modal-content {
    width: 90%;
    max-width: 90%;
  }

  .edit-modal {
    width: 90%;
  }

  .Submit-button {
    font-size: 14px;
    padding: 0.6em 1.2em;
  }

  .Chapter-list {
    font-size: 0.9rem;
    padding: 5px 15px;
  }

  .media-player, .ppt-viewer {
    max-width: 100%;
    padding: 5px;
    margin-top: 10px;
  }

  .media-player iframe {
    height: 200px;
  }

  .close {
    font-size: 25px;
    margin-bottom: 200px;
  }

  .modal-content input {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .chapter-details {
    padding: 5px;
  }

  .Three-button {
    flex-direction: column;
    gap: 8px;
    margin: 15px 0;
  }

  .First-button, .Second-button, .Third-button, .Fourth-button, .Fifth-button, .Sixth-button {
    padding: 8px 12px;
    font-size: 13px;
    max-width: 100%;
  }

  .modal-content input {
    padding: 8px;
  }

  .Submit-button {
    font-size: 13px;
    padding: 0.5em 1em;
  }

  .Chapter-list {
    padding: 5px 10px;
    font-size: 0.4rem;
  }

  .media-player iframe {
    height: 180px;
  }

  /* Adjust input fields in modals */
  .edit-modal input {
    padding: 8px;
    font-size: 13px;
  }

  .modal-content h3 {
    font-size: 1.2rem;
  }

  .topic-icons {
    gap: 5px;
    flex-direction: column;
    justify-content: center;
  }

  .icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
    padding: 2px 5px;
   
  }

  /* Adjust the layout of the content */
  .topic-name {
    flex-direction: column;
    align-items: center;
  }

  .modal-content {
    padding: 15px;
    max-width: 90%;
  }
}

