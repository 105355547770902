.Container{
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column; 
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Noto, sans-serif;
}
.container-fluid{
  box-sizing: border-box; 
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
}

.Navbar {
  position: relative;
  top: 0;
  background-color: #EAF6FF;
  padding: 5px;
  height: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
.Navbar-Heading {
  flex-grow: 1;
  text-align: center;
  font-size: 0.8rem;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Noto, sans-serif;
  color: #0056b3;
  font-weight: bold;
  font-style: inherit;
}

.Adjust-Sidebar{
  margin-left: 20px;
}

.Funscholar-Logo {
  background: transparent;
}

.Funscholar-Logo img {
  width: 100%; 
  height: 120px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.Funscholar-Logo:hover {
  transform: scale(1.05); 
  opacity: 0.9;
}


.profile-container {
  display: flex;
  align-items: center; 
  cursor: pointer;
}

.profile-icon i {
  font-size: 30px;
}

.profile-popup {
  position: absolute;
  right: 20px; 
  top: 65px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.profile-popup p {
  font-size: 16px;
  color: #333; 
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.profile-popup button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.profile-popup button:hover {
  background-color: #0056b3;
}


.sidebar {
  background-color: #EAF6FF;
  width: 200px;
  transition: width 0.3s ease, margin-left 0.3s ease;
  overflow-y: auto;
  padding: 20px 10px;
  height: calc(100% - 110px);
  scrollbar-width: thin;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.sidebar-container {
  background-color: #EAF6FF;
}

.sidebar-collapsed {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapsed-menu i {
  font-size: 23px;
  line-height: 2.5;
  transition: font-size 0.3s ease;
}

.sidebar .menu-item {
  padding: 2px 3px 0 5px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
}


.submenu {
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 20%;
  
}

.submenu .submenu-item {
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submenu .submenu-item:hover {
  background-color: #FB8500;
  color: white;
}

.subsubmenu {
  border-radius: 10px;
  padding: 5px 10px;
}

.subsubmenu .subsubmenu-item {
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.subsubmenu .subsubmenu-item:hover {
  background-color: #FB8500;
  color: white;
  cursor: pointer;
}

.sidebar-icon i:hover{
  color: #ccc;
}

.Content {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Noto, sans-serif;
  flex: 1; 
  padding: 5px;
  background: linear-gradient(to bottom,  rgba(186,221,242,1) 0%,rgba(246,205,188,1) 100%);
  overflow-y: scroll;
  scrollbar-width: thin;
}



