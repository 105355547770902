.Total-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Topbar_Main {
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Topbar_Container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 10px;
}

.NavBarRightContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Topbar_MenuItemsContainer {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  flex-grow: 1;
}

.Topbar_NavItemsContainer {
  display: flex;
  gap: 20px;
  list-style: none;
}

.Topbar_NavItemsContainer li {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}
.Topbar_NavItemsContainer li:hover {
  background-color: #f0f0f0; 
}

.Button-container {
  display: flex;
  justify-content: flex-end;
}
 
.uiverse {
  position: relative;
  background: #ffffff;
  color: #000;
  padding: 5px 10px;
  margin: 5px;
  border: 2px solid #007bff;
  border-radius: 10px;
  width: 95px;
  height: 30px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  top: -5px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse:hover .tooltip {
  top: 45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

svg:hover span,
svg:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.uiverse:hover,
.uiverse:hover .tooltip,
.uiverse:hover .tooltip::before {
  background: linear-gradient(320deg, rgb(3, 77, 146), rgb(0, 60, 255));
  color: #ffffff;
}

.Topbar_twoD_Button_getStart__sxCLK {
  margin-left: 10px;
}



.slider-container {
  width: 60%;
  height: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20%;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}



.MuiSvgIcon-root {
  width: 24px;
  height: 24px;
}

.section2 {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section2 h4 {
  font-size: 1.7rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.Section2Text {
  font-size: 1.3rem;
  color: #666;
  margin-bottom: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Section3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Section3>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.Section3BookImage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-bottom: 1px;
  background-color: #f0f0f0;
}

.Section3BookImage img {
  width: 40px;
  height: 40px;
}

.Section3Text {
  font-size: 1rem;
  color: #555;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.Section3Text a {
  color: #fe5c0d;
  text-decoration: none;
}

.Section3Text a:hover {
  text-decoration: underline;
}

.HomePage {
  padding: 20px;
}

.HomePage-Section1 {
  text-align: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Homepage_randomTextContainer {
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 45px;
  font-weight: bold;
  font-size: 2.5rem;
  margin-top: 10px;
  font-style: italic;
  color: white;
  background-color: #245353;
  text-align: center;
  border-radius: 25px;
}

.Homepage_randomTextContainer div {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  animation: slideIn 9s ease-in-out infinite;
}

.Homepage_randomTextContainer div:nth-child(1) {
  animation-delay: 0s;
}

.Homepage_randomTextContainer div:nth-child(2) {
  animation-delay: 3s;
}

.Homepage_randomTextContainer div:nth-child(3) {
  animation-delay: 6s;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }

  40% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}


.homepageV2_buttonStyle__ujC5_ {
  margin: 20px 0;
  color: black;
}

.ThreeDbuttonTwo_button2 {
  width: 100%;
  max-width: 250px;
  display: inline-block;
  padding: 20px 40px;
  background-color: orangered;
  color: white;
  text-decoration: none;
  border-radius: 15px;
  font-size: 1.5rem;
}

.homepageV2_section1set2__0ZVFj {
  margin-top: 20px;
}

.homepageV2_section1set2Text__5E7LA {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.CustomSlider_wrapper__bkpRB {
  overflow: hidden;
  width: 90%;
  height: 90px;
  margin-left: 5%;
}

.CustomSlider_slider__P5SEQ {
  display: flex;
  width: calc(100% * 2);
  animation: slide 40s linear infinite;
}

.CustomSlider_slide__4GxB_ {
  display: flex;
  flex: 1;
  box-sizing: border-box;
}

.CustomSlider_slide__4GxB_ img {
  margin-right: 60px;
  height: 6vh;
}

.CustomSlider_slide__4GxB_ img:last-child {
  margin-right: 10px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.homepageV2_section3Background__FPcfs {
  background-color: #f5f5f5;
  padding: 20px;
}


.homepageV2_section3__lRfat {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.section5-carousel {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slides-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
}

.section3Card_section3CardStyle__teP_Y {
  background: grey;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}


.sectionHeader_textWhite__WfF1N {
  color: black;
  font-size: 1.5em;
  margin-bottom: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.section3Card_section3CardBodyStyle__YIUOG {
  color: white;
}

.section3Card_section3CardBodyStyle__YIUOG img {
  position: relative;
  width: 50%;
  height: auto;
  border-radius: 8px;
  align-items: center;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
  z-index: 1;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

.section5-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.section5-indicator {
  background-color: #ddd;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.section5-indicator.indicator-active {
  background-color: #333;
}

.Home-middle {
  padding: 20px;
  background-color: #f8f9fa;
}

.homepageV2_multiColorBackground__S6pv5 {
  background: linear-gradient(to right, #00aaff, #f5a623);
  padding: 40px;
}

.homepageV2_section7__ApKTY {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.sectionHeader_textWhite__WfF1N {
  color: #fff;
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepageV2_section7Para__bAXxQ {
  color: black;
  font-size: 1.125rem;
  margin-bottom: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.homepageV2_section7TextContainer__D0rrX {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.homepageV2_section7TextContainerInner__e3rhI {
  text-align: center;
  flex: 1;
  min-width: 200px;
}

.homepageV2_section7IconContainer__V_eOs {
  margin-bottom: 10px;
}

.homepageV2_section7IconContainer__V_eOs img {
  max-width: 70px;
  height: auto;
}

.homepageV2_section7TextContainerInner__e3rhI h2 {
  font-size: 1.5rem;
  color: #fff;
  margin: 10px 0;
}

.homepageV2_section7TextContainerInner__e3rhI p {
  color: #ddd;
  font-size: 1rem;
}

.homepageV2_section7btn__Myzgm {
  margin-top: 30px;
}

.Button-middle {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  background-color: rgb(240, 232, 229);
  color: #000;
  transition: background-color 0.3s, color 0.3s;
}

.Button-middle:hover {
  background-color: #1bd1b9;
  color: #000;

}

.Footer {
  background-color: #f5f5f5;
  /* Background color of footer */
  padding: 20px 0;
}

.Footer_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.Footer_section1,
.Footer_quickLinks,
.Footer_businessEnquiry,
.Footer_apps {
  flex: 1;
  margin: 10px 20px;
}

.Footer_brand img {
  max-width: 150px;
  margin-bottom: 10px;
}

.Footer_brand p {
  font-size: 14px;
  color: #333;
}

.Footer_mainBrandLogo img {
  max-width: 120px;
  margin-top: 10px;
}

.Footer_quickLinks h5,
.Footer_businessEnquiry h5 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.Footer_quickLinks ul,
.Footer_businessEnquiry ul {
  list-style: none;
  padding: 0;
}

.Footer_quickLinks ul li,
.Footer_businessEnquiry ul li {
  margin-bottom: 10px;
}

.Footer_quickLinks ul li a,
.Footer_businessEnquiry ul li a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
  transition: color 0.3s;
}

.Footer_quickLinks ul li a:hover,
.Footer_businessEnquiry ul li a:hover {
  color: #0056b3;
}

.Footer_icon {
  display: inline-block;
  margin-right: 10px;
}

.Footer_icon img {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.ButtonCard_container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.ButtonCard {
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s;
}

.ButtonCard:hover {
  transform: scale(1.05);
}

.ButtonCard img {
  display: block;
  margin: 0 auto 10px;
}

.ButtonCard_label {
  font-size: 14px;
  color: #333;
}



@media (min-width: 1024px) {
  .Homepage_randomTextContainer {
    width: 30%;
    font-size: 2rem;
    margin-left: 35%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .slider-container {
    width: 80%;
    margin: 0 auto;
  }

  .Topbar_MenuItemsContainer {
    font-size: 0.9rem;
  }

  .Homepage_randomTextContainer {
    width: 50%;
    font-size: 2rem;
  }

  .ThreeDbuttonTwo_button2 {
    width: auto;
    margin: 5px;
  }

  .section3Card_section3CardStyle__teP_Y {
    padding: 20px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {

  .Topbar_Main {
    padding: 5px;
  }

  .Topbar_Container {
    padding: 5px 10px;
    flex-direction: column;
    margin-left: 0;
  }

  .NavBarRightContainer {
    width: 100%;
  }

  .Button-container{
    gap: 5px;
  }

  .NavBarRightContainer.active {
    display: flex;
  }

  .Topbar_MenuItemsContainer {
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }

  .Topbar_NavItemsContainer {
    display: none;
    flex-direction: column;
  }

  .Topbar_NavItemsContainer.open {
    display: flex;
  }

  .Topbar_NavItemsContainer.closed {
    display: none;
  }


  .Topbar_mobileMenuTabView {
    display: block;
    cursor: pointer;
  }

  .Topbar_mobileMenuTabView:hover {
    opacity: 0.8;
  }

  .slider-container {
    align-items: center;
    text-align: center;
    height: 300px;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.open+.Topbar_MenuItemsContainer {
    display: flex;
  }

  .MuiButtonBase-root {
    width: 100%;
    margin: 10px 0;
  }

  .Total-content {
    flex-direction: column;
  }

  .Homepage_randomTextContainer {
    font-size: 1.5rem;
  }
  .section2 {
    padding: 15px;
    margin: 10px;
  }

  .ThreeDbuttonTwo_button2 {
    width: 100%;
    padding: 15px 0;
    font-size: 1.2rem;
  }

  .arrow {
    padding: 15px;
  }

  .section5-indicator {
    width: 15px;
    height: 15px;
  }

  .Footer_container {
    flex-direction: column;
    align-items: center;
  }

  .Footer_section1,
  .Footer_quickLinks,
  .Footer_businessEnquiry,
  .Footer_apps {
    margin: 20px 0;
    text-align: center;
  }

  .ButtonCard_container {
    flex-wrap: wrap;
  }

  .ButtonCard {
    margin-bottom: 10px;
  }
}


@media (max-width: 480px) {
  .Topbar_Container {
    padding: 5px 10px;
  }

  .Homepage_randomTextContainer {
    width: 80%;
    font-size: 1.6rem;
    margin-left: 10%;
  }

  .slider-container {
    height: 250px;
  }

  .section3Card_section3CardStyle__teP_Y {
    padding: 15px;
    margin: 5px;
  }

  .section3Card_section3CardStyle__teP_Y {
    padding: 8px;
  }

  .sectionHeader_textWhite__WfF1N {
    font-size: 14px;
  }

  .homepageV2_section7Para__bAXxQ {
    font-size: 14px;
  }

  .homepageV2_section7TextContainer__D0rrX {
    flex-wrap: nowrap;
  }

  .homepageV2_section7TextContainerInner__e3rhI {
    min-width: auto;
  }


  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.1rem;
  }

  .sectionHeader_textWhite__WfF1N span {
    font-size: 11px;
  }

  .section3Card_section3CardBodyStyle__YIUOG p {
    font-size: 12px;
  }

  .arrow {
    font-size: 18px;
  }

  .section5-indicator {
    width: 6px;
    height: 6px;
  }

  .CustomSlider_wrapper__bkpRB {
    width: 100%;
  }

  .section5-carousel {
    flex-direction: column;
  }

  .Footer_brand {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  .Footer_brand img {
    width: 20%;
    margin-right: 15px;
  }

  .Footer_brand p {
    margin: 0;
    width: 100%;
  }
}